// Module to contain enums
// Most of enums are duplicated and checked on the backend side so it's better to keep it synchronic

// User roles enum
export const UsersRoles = {
  NONE: 'none',
  ADMIN: 'admin',
  COACH: 'coach',
  PLAYER: 'player',
};

// Team statuses on tournaments enum
export const TournTeamStatuses = {
  INVITED: 'invited',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

// Player document's types enum
export const DocTypes = {
  ID: 'ID',
  BIRTHCERTIFICATE: 'Birth certificate',
  SCHOOLREPORTCARD: 'School report card',
  CERTIFICATE: 'Certificate',
  LICENSE: 'License',
  OTHER: 'Other',
};

// Payment purposes enum
export const PaymentPurposes = {
  CARD: 'card',
  TOURNAMENT: 'tournament',
};

// Currencies enum
export const Currencies = {
  USD: 'USD',
};

// Sports enum
export const Sports = {
  FOOTBALL7V7: 'Football 7v7',
  FLAGFOOTBALL7V7: 'Flag football 7v7',
  FOOTBALL9V9: 'Football 9v9',
  FLAGFOOTBALL9V9: 'Flag football 9v9',
  FOOTBALL11V11: 'Football 11v11',
  FLAGFOOTBALL11V11: 'Flag football 11v11',
};

// Genders enum
export const Genders = {
  MALE: 'male',
  FEMALE: 'female',
};

// Ages enum
export const Ages = {
  7: '8U',
  8: '9U',
  9: '10U',
  10: '11U',
  11: '12U',
  12: '13U',
  13: '14U',
  14: '15U',
  15: '8th Grade',
  16: '9th Grade',
  17: 'High School',
};
