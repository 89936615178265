// Player details interface

import { Link, useNavigate } from 'react-router-dom';
import { useFirebase } from './FirebaseContextProvider';
import moment from 'moment';
import PlayersList from './PlayersList';
import TournamentsList from './TournamentsList';
import CardTemplate from './CardTemplate';
import Gallery from './Gallery';
import PlayerCardEdit from './PlayerCardEdit';
import Tabs from './Tabs';
import PlayersDocsChecklist from './PlayersDocsChecklist';
import PropItem from './PropItem';
import PropItemsBlock from './PropItemsBlock';
import { DocTypes } from '../utils/enums';
import { capFirstLetter } from '../utils/misc';
import { userUpdate } from '../utils/firebase';
import './PlayerCard.scss';

export default function PlayerCard({ player }) {
  const navigate = useNavigate();
  const { user, users } = useFirebase();

  const {
    id,
    imgUrl,
    imgThumbUrl,
    name,
    team,
    birthDate,
    gender,
    card,
    docs,
    phone,
    email,
  } = player;

  const isTheUser = user.id === id;

  const coach = users.find((user) => user.teams?.includes(team));

  const { expireDate: cardExp, number: cardNum } = card ?? {};

  const onUploadImg = async (file, index, type) => {
    try {
      const newDocs = docs.map(({ image, type }) => ({ image, type }));

      const doc = { image: file.name, type: type ? type : DocTypes.OTHER };

      if (index !== undefined) {
        newDocs[index] = doc;
      } else newDocs.push(doc);

      await userUpdate({ id, docs: newDocs });
    } catch (error) {
      console.error(error.message);
    }
  };

  const onDeleteImg = async (index) => {
    try {
      const newDocs = docs
        .map(({ image, type }) => ({ image, type }))
        .filter((doc, i) => i !== index);

      await userUpdate({ id, docs: newDocs });
    } catch (error) {
      console.error(error.message);
    }
  };

  const GalleryWrap = (
    <div className='block'>
      <Gallery
        images={docs.map((doc) => ({
          title: doc.type,
          thumbUrl: doc.imgThumbUrl,
          url: doc.imgUrl,
          path: `users/${id}/`,
        }))}
        newImgUploadPath={`users/${id}/`}
        onUploadImg={isTheUser ? onUploadImg : undefined}
        onDeleteImg={isTheUser ? onDeleteImg : undefined}
        types={[
          DocTypes.ID,
          DocTypes.BIRTHCERTIFICATE,
          DocTypes.SCHOOLREPORTCARD,
          DocTypes.OTHER,
        ]}
      />
    </div>
  );

  return (
    <CardTemplate
      header='Player'
      className='player-card'
      cardBtnText={isTheUser ? undefined : 'Back'}
      cardBtnOnClick={isTheUser ? undefined : () => navigate(-1)}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgTitle={name}
      name={name}
      details={
        <div className='props-checklist-wrap'>
          <PropItemsBlock>
            <PropItem prop='Gender' value={capFirstLetter(gender)} />
            <PropItem
              prop='Birth date'
              value={
                birthDate ? moment(birthDate.toDate()).format('MMM D, YY') : ''
              }
            />
            <PropItem prop='Phone' value={phone} />
            <PropItem prop='e-mail' value={email} />
            <div className='team-wrap'>
              <p className=' gray-500 font-medium'>Coach: </p>
              <Link
                to={'/coaches/' + coach?.id}
                className='coach-link font-medium'
              >
                {coach?.name}
              </Link>
            </div>
            <PropItem
              prop='Card'
              value={
                cardNum && cardExp
                  ? `#${cardNum} exp.${moment(cardExp.toDate()).format(
                      'MMM D, YY'
                    )}`
                  : 'No card'
              }
            />
          </PropItemsBlock>
          <PlayersDocsChecklist docs={docs} />
        </div>
      }
      editForm={isTheUser && <PlayerCardEdit player={player} />}
    >
      {!isTheUser && GalleryWrap}
      {isTheUser && (
        <Tabs
          className='player-card-tabs'
          tabs={[
            {
              title: 'Documents',
              hash: '#documents',
              component: GalleryWrap,
            },
            {
              title: 'Team',
              hash: '#team',
              component: (
                <div className='block'>
                  <PlayersList teamId={team} />
                </div>
              ),
            },
            {
              title: 'Tournaments',
              hash: '#tournaments',
              component: (
                <div className='block'>
                  <TournamentsList teamId={team} />
                </div>
              ),
            },
          ]}
        />
      )}
    </CardTemplate>
  );
}
