// Tournament details interface

import { useNavigate } from 'react-router-dom';
import { useFirebase } from './FirebaseContextProvider';
import { UsersRoles } from '../utils/enums';
import TeamsList from './TeamsList';
import CardTemplate from './CardTemplate';
import PropItem from './PropItem';
import PropItemsBlock from './PropItemsBlock';
import TournamentCardEdit from './TournamentCardEdit';
import { formatDateRange, switchGender } from '../utils/misc';
import { tournamentUpdate } from '../utils/firebase';
import './TournamentCard.scss';

export default function TournamentCard({ tournament }) {
  const navigate = useNavigate();
  const { user } = useFirebase();

  const {
    id,
    imgUrl,
    imgThumbUrl,
    name,
    location,
    beginDate,
    endDate,
    sports,
    genders,
    ageFrom,
    ageTo,
  } = tournament;

  const userIsAdmin = user.role === UsersRoles.ADMIN;

  const onDelete = async () => {
    try {
      await tournamentUpdate({ id, active: false });
    } catch (error) {
      console.error(error?.message);
    }
  };

  return (
    <CardTemplate
      header='Tournament'
      className='tournament-card'
      cardBtnText='Back'
      cardBtnOnClick={() => navigate(-1)}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgTitle='Tournament emblem'
      name={name}
      details={
        <PropItemsBlock>
          <p className='fa-calendar'>
            {formatDateRange(beginDate?.toDate(), endDate?.toDate())}
          </p>
          <PropItem prop='Location' value={location} />
          <PropItem prop='Sport' value={sports?.join(', ')} />
          <PropItem
            prop='Division'
            value={`${genders
              ?.map((gender) => switchGender(gender))
              .join(', ')}, ${
              ageFrom === ageTo ? ageFrom : ageFrom + ' - ' + ageTo
            }`}
          />
        </PropItemsBlock>
      }
      onDelete={userIsAdmin && onDelete}
      afterDeleteNavPath='/admin#tournaments'
      editForm={userIsAdmin && <TournamentCardEdit tournament={tournament} />}
    >
      <div className='block teams-list-wrap'>
        <TeamsList tournamentId={id} />
      </div>
    </CardTemplate>
  );
}
