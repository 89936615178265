// Players list

import { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { userUpdate } from '../utils/firebase';
import PlayersDocsChecklist from './PlayersDocsChecklist';
import { useFirebase } from './FirebaseContextProvider';
import { useModal } from './ModalProvider';
import { UsersRoles } from '../utils/enums';
import Spinner from './Spinner';
import './PlayersList.scss';
import { capFirstLetter } from '../utils/misc';

export default function PlayersList({ teamId }) {
  const [spinner, setSpinner] = useState(false);
  const { user, users } = useFirebase();
  const { openModal } = useModal();

  const isAdmin = user.role === UsersRoles.ADMIN;
  const isTheCoach = user.teams?.includes(teamId);

  const isDisabled = !(isAdmin || isTheCoach);

  const onRemove = async (e, id) => {
    e.preventDefault();

    openModal({
      header: `Remove player`,
      body: (
        <p className='font-16'>
          {`Are you sure you want to remove the player?`}
        </p>
      ),
      buttons: [
        {
          text: 'Cancel',
          className: 'bw',
        },
        {
          text: 'Remove',
          className: 'danger',
          onClick: async () => {
            try {
              setSpinner(true);
              await userUpdate({ id, team: '' });
              setSpinner(false);
            } catch (error) {
              setSpinner(false);
              console.error(error?.message);
            }
          },
        },
      ],
    });
  };

  return (
    <div className='players-list'>
      <ul className='header tab-header'>
        <li className='col-1' />
        <li className='col-2'>Name</li>
        <li className='col-3'>Gender, Birth&nbsp;date</li>
        <li className='col-23'>Name, Gender, Birth&nbsp;date</li>
        <li className='col-234'>Name, Gender, Birth&nbsp;date, Card</li>
        <li className='col-4'>Card</li>
        <li className='col-5'>Docs</li>
        {(isAdmin || isTheCoach) && <li className='col-6' />}
      </ul>
      {users
        .filter((user) => user.role === UsersRoles.PLAYER)
        .filter((user) => user.team === teamId)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((user, i) => {
          const { id, name, imgThumbUrl, birthDate, gender, card, docs } = user;

          const { expireDate: cardExp, number: cardNum } = card ?? {};

          const CardInfo = () => {
            if (cardNum && cardExp) {
              return `#${cardNum} exp.\u00A0${moment(cardExp.toDate()).format(
                'MMM\u00A0DD,\u00A0YY'
              )}`;
            } else
              return <span className='danger upper font-medium'>No card</span>;
          };

          return (
            <Link
              to={'/players/' + id}
              className={'player' + (isDisabled ? ' disabled' : '')}
              key={i}
              onClick={isDisabled ? (e) => e.preventDefault() : undefined}
            >
              <div
                className='bg-image wh-64'
                style={{
                  backgroundImage: `url(${imgThumbUrl})`,
                }}
              />
              <p className='col-2'>
                <span className='font-medium upper'>{name}</span>
                <br />
                <span className='dob'>
                  {capFirstLetter(gender)}
                  <br />
                  {moment(birthDate.toDate()).format('MMM D, YY')}
                  <br />
                </span>
                <span className='card'>
                  <CardInfo />
                </span>
              </p>
              <p className='col-3'>
                {capFirstLetter(gender)},
                <br />
                {moment(birthDate.toDate()).format('MMM D, YY')}
              </p>
              <p className='col-4'>
                <CardInfo />
              </p>
              <div className='col-5'>
                <PlayersDocsChecklist docs={docs} />
              </div>
              {(isAdmin || isTheCoach) && (
                <div className='col-6'>
                  <button
                    className='small danger remove-btn-big'
                    onClick={(e) => onRemove(e, id)}
                  >
                    Remove
                  </button>
                  <button
                    className='remove-btn-small small white'
                    onClick={(e) => onRemove(e, id)}
                  >
                    <span className='fa-cross danger' />
                  </button>
                </div>
              )}
            </Link>
          );
        })}
      <Spinner show={spinner} />
    </div>
  );
}
