// Team details interface

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFirebase } from './FirebaseContextProvider';
import CardTemplate from './CardTemplate';
import TournamentsList from './TournamentsList';
import Tabs from './Tabs';
import PlayersList from './PlayersList';
import PropItem from './PropItem';
import PropItemsBlock from './PropItemsBlock';
import TeamCardEdit from './TeamCardEdit';
import { useModal } from './ModalProvider';
import { UsersRoles } from '../utils/enums';
import { teamUpdate, userRemoveTeam, tokenCreate } from '../utils/firebase';
import './TeamCard.scss';
import { switchGender } from '../utils/misc';

export default function TeamCard({ team }) {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const { user, users } = useFirebase();
  const { openModal } = useModal();

  const { id, imgUrl, imgThumbUrl, name, location, age, gender, sport } = team;

  const userIsAdmin = user.role === UsersRoles.ADMIN;
  const userIsTheCoach = user.teams?.includes(id);

  const onInvite = async (role, text) => {
    try {
      setSpinner(true);
      const res = await tokenCreate({ team: id, role });
      const link = `${process.env.REACT_APP_ROOT_URL}/invite/${res.data}`;
      setSpinner(false);
      openModal({
        header: `Invite ${text}`,
        body: (
          <>
            <p className='font-16'>
              {`Please send the link below to the ${text} you want to invite:`}
              <br />
              <br />
              <span
                className='primary font-medium'
                style={{ wordBreak: 'break-all' }}
              >
                {link}
              </span>
            </p>
          </>
        ),
        buttons: [{ text: 'Ok' }],
      });
    } catch (error) {
      setSpinner(false);
      console.error(error.message);
    }
  };

  const onRemoveCoach = () =>
    openModal({
      header: `Remove coach`,
      body: (
        <p className='font-16'>
          {`Are you sure you want to remove the coach?`}
        </p>
      ),
      buttons: [
        {
          text: 'Cancel',
          className: 'bw',
        },
        {
          text: 'Remove',
          className: 'danger',
          onClick: async () => {
            try {
              setSpinner(true);
              await userRemoveTeam({
                id: user.id,
                team: id,
              });
              setSpinner(false);
            } catch (error) {
              setSpinner(false);
              console.error(error.message);
            }
          },
        },
      ],
    });

  return (
    <CardTemplate
      header='Team'
      className='team-card'
      cardBtnText='Back'
      cardBtnOnClick={() => navigate(-1)}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgTitle='Team emblem'
      name={name}
      details={
        <PropItemsBlock spinner={spinner}>
          <PropItem prop='Location' value={location} />
          <PropItem prop='Sport' value={sport} />
          <PropItem prop='Division' value={`${switchGender(gender)}, ${age}`} />
          <div className='coach-wrap'>
            <p className='gray-500 font-medium'>Coach: </p>
            <div className='coach-list'>
              {users
                .filter((user) => user.role === UsersRoles.COACH)
                .filter((user) => user.teams.includes(id))
                .map((user, i, usersFiltered) => (
                  <div className='coach-item' key={user.id}>
                    <Link
                      to={'/coaches/' + user.id}
                      className='user-link font-medium'
                      key={user.id}
                    >
                      {user.name}
                      {!userIsAdmin && i < usersFiltered.length - 1 && ','}
                    </Link>
                    {userIsAdmin && (
                      <button
                        className='remove-btn-small small white'
                        onClick={onRemoveCoach}
                      >
                        <span className='fa-cross danger' />
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className='buttons'>
            {/* {userIsAdmin && <button className='small bw'>Discount</button>} */}
            {userIsAdmin && (
              <button
                className='small bw'
                onClick={() => onInvite(UsersRoles.COACH, 'coach')}
              >
                Invite coach
              </button>
            )}
            {(userIsAdmin || userIsTheCoach) && (
              <button
                className='small bw'
                onClick={() => onInvite(UsersRoles.PLAYER, 'players')}
              >
                Invite players
              </button>
            )}
          </div>
        </PropItemsBlock>
      }
      onDelete={
        userIsAdmin && (async () => await teamUpdate({ id, active: false }))
      }
      afterDeleteNavPath='/admin#teams'
      editForm={(userIsAdmin || userIsTheCoach) && <TeamCardEdit team={team} />}
    >
      <Tabs
        className='team-card-tabs'
        tabs={[
          {
            title: 'Players',
            hash: '#players',
            component: (
              <div className='block'>
                <PlayersList teamId={id} />
              </div>
            ),
          },
          {
            title: 'Tournaments',
            hash: '#tournaments',
            component: (
              <div className='block'>
                <TournamentsList teamId={id} />
              </div>
            ),
          },
        ]}
      />
    </CardTemplate>
  );
}
