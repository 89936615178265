// Edition form for team card

import * as Yup from 'yup';
import { teamUpdate, teamCreate } from '../utils/firebase';
import Input from './Input';
import Select from './Select';
import CardEditTemplate from './CardEditTemplate';
import { Sports, Genders, Ages } from '../utils/enums';
import './TeamCardEdit.scss';
import { switchGender } from '../utils/misc';

export default function TeamCardEdit({ team }) {
  const { id, name, location, age, gender, sport, imgUrl, imgThumbUrl } = team;

  const initialValues = {
    name: name || '',
    location: location || '',
    age: age || null,
    gender: gender || null,
    sport: sport || null,
  };

  const validationSchema = new Yup.ObjectSchema({
    name: Yup.string().max(100, 'Too long').required('Required'),
    location: Yup.string().max(100, 'Too long').required('Required'),
    age: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
    sport: Yup.string().required('Required'),
  });

  return (
    <CardEditTemplate
      className='team-card-edit'
      id={id}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgPath={`teams/${id}/`}
      formConfig={{
        initialValues,
        validationSchema,
      }}
      create={teamCreate}
      update={teamUpdate}
      afterCreateNavPath='/admin#teams'
    >
      {(formik) => (
        <>
          <Input
            name='name'
            placeholder='Name'
            {...formik.getFieldProps('name')}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
          <Input
            name='location'
            placeholder='Location'
            {...formik.getFieldProps('location')}
            error={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : null
            }
          />
          <div className='select-wrap'>
            <Select
              options={Object.values(Sports)}
              value={formik.values.sport}
              placeholder='Sport'
              onChange={async (val) => {
                await formik.setFieldValue('sport', val);
                await formik.setTouched({ ...formik.touched, sport: true });
              }}
              error={
                formik.touched.sport && formik.errors.sport
                  ? formik.errors.sport
                  : null
              }
            />
            <Select
              options={Object.values(Genders).map((gender) =>
                switchGender(gender)
              )}
              value={switchGender(formik.values.gender)}
              placeholder='Gender'
              onChange={async (val) => {
                await formik.setFieldValue('gender', switchGender(val));
                await formik.setTouched({ ...formik.touched, gender: true });
              }}
              error={
                formik.touched.gender && formik.errors.gender
                  ? formik.errors.gender
                  : null
              }
            />
            <Select
              options={Object.values(Ages)}
              value={formik.values.age}
              placeholder='Age'
              onChange={async (val) => {
                await formik.setFieldValue('age', val);
                await formik.setTouched({ ...formik.touched, age: true });
              }}
              error={
                formik.touched.age && formik.errors.age
                  ? formik.errors.age
                  : null
              }
            />
          </div>
        </>
      )}
    </CardEditTemplate>
  );
}
