// Edition form for player card

import { Timestamp } from 'firebase/firestore';
import * as Yup from 'yup';
import CardEditTemplate from './CardEditTemplate';
import isMobilePhone from 'validator/lib/isMobilePhone';
import Input from './Input';
import { userUpdate } from '../utils/firebase';
import { capFirstLetter, emailRegExp, decapFirstLetter } from '../utils/misc';
import InputDate from './InputDate';
import Select from './Select';
import { Genders } from '../utils/enums';
import './PlayerCardEdit.scss';

export default function PlayerCardEdit({ player }) {
  const {
    id,
    name,
    phone,
    email,
    birthDate,
    imgUrl,
    imgThumbUrl,
    card,
    gender,
  } = player;

  const initialValues = {
    name: name || '',
    phone: phone || '',
    email: email || '',
    birthDate:
      birthDate instanceof Timestamp ? birthDate.toDate() : birthDate || null,
    gender: gender || '',
    card: {
      expireDate:
        card?.expireDate instanceof Timestamp
          ? card.expireDate.toDate()
          : card?.expireDate || null,
      issueDate:
        card?.issueDate instanceof Timestamp
          ? card.issueDate.toDate()
          : card?.issueDate || null,
      number: card?.number || '',
    },
  };

  const validationSchema = new Yup.ObjectSchema({
    name: Yup.string().max(100, 'Too long').required('Required'),
    gender: Yup.string().required('Required'),
    phone: Yup.string()
      .required('Required')
      .test('is-valid-phone', 'Invalid', (value) =>
        value ? isMobilePhone(value, 'en-US', { strictMode: false }) : false
      ),
    email: Yup.string().matches(emailRegExp, 'Invalid').required('Required'),
    birthDate: Yup.date().required('Required'),
  });

  return (
    <CardEditTemplate
      className='player-card-edit'
      id={id}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgPath={`users/${id}/`}
      formConfig={{
        initialValues,
        validationSchema,
      }}
      update={userUpdate}
      create={() => {}}
      afterCreateNavPath={`/players/${id}`}
    >
      {(formik) => (
        <>
          <div className='general-info'>
            <Input
              name='name'
              placeholder='Name'
              className='name'
              {...formik.getFieldProps('name')}
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
            />
            <Select
              options={Object.values(Genders).map((gen) => capFirstLetter(gen))}
              value={capFirstLetter(formik.values.gender)}
              onChange={(val) =>
                formik.setFieldValue('gender', decapFirstLetter(val))
              }
              placeholder='Gender'
              error={
                formik.touched.gender && formik.errors.gender
                  ? formik.errors.gender
                  : null
              }
            />
            <InputDate
              value={formik.values.birthDate}
              onChange={async (newDate) => {
                await formik.setFieldValue('birthDate', newDate);
              }}
              className='birth-date'
              maxDate={new Date()}
              placeholder='Birth date'
              error={
                formik.touched.birthDate && formik.errors.birthDate
                  ? formik.errors.birthDate
                  : null
              }
            />
          </div>
          <div className='contacts-wrap'>
            <Input
              name='phone'
              placeholder='Phone'
              className='phone'
              {...formik.getFieldProps('phone')}
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null
              }
            />
            <Input
              name='email'
              placeholder='e-mail'
              {...formik.getFieldProps('email')}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </div>
          <div className='card-wrap'>
            <Input
              name='card.number'
              placeholder='Card number'
              {...formik.getFieldProps('card.number')}
              error={
                formik.touched.card?.number && formik.errors.card?.number
                  ? formik.errors.card?.number
                  : null
              }
            />
            <InputDate
              value={formik.values.card.issueDate}
              onChange={async (newDate) => {
                await formik.setFieldValue('card.issueDate', newDate);
              }}
              className='issue-date'
              placeholder='Card issue date'
              error={
                formik.touched.card?.issueDate && formik.errors.card?.issueDate
                  ? formik.errors.card?.issueDate
                  : null
              }
            />
            <InputDate
              value={formik.values.card.expireDate}
              onChange={async (newDate) => {
                await formik.setFieldValue('card.expireDate', newDate);
              }}
              className='expire-date'
              placeholder='Card exp date'
              error={
                formik.touched.card?.expireDate &&
                formik.errors.card?.expireDate
                  ? formik.errors.card?.expireDate
                  : null
              }
            />
          </div>
        </>
      )}
    </CardEditTemplate>
  );
}
